
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  Button,
  Collapse,
  Box,
  Grid,
  InputAdornment,
  createTheme,
  ThemeProvider,
  Tooltip,
  Chip,
} from "@mui/material";
import apiHelper from "../../apiCentral/apiHelper";
import { path } from "../../apiCentral/path";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterPopup from "./popups/FilterPopup";
import CardDetails from "./CardDetails";
import { styled } from "@mui/system";
import { saveAs } from "file-saver";
import { updateSelectedFields } from "../../utils";
import { cardTypeMap } from "../../contants";
import TablePaginationActions from "./TablePaginationAction";
import maleIcon from "../../assets/icons/male_icon.svg";
import femaleIcon from "../../assets/icons/female_icon.svg";
import profileIcon from "../../assets/icons/profile_icon.svg";
import callIcon from "../../assets/icons/call_icon.svg";
import locationIcon from "../../assets/icons/location.svg";
import mailIcon from "../../assets/icons/mail_icon.svg";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
const _ = require("lodash");
const XLSX = require("xlsx");

const defaultPageSize = 10;

const initialRequest = {
  search: "",
  dateFilter: {
    startDate: null,
    endDate: null,
  },
  srFilter: [],
  typeFilter: [],
  cardCategoryFilter: [],
  pagination: {
    page: 0,
    size: defaultPageSize,
  },
};

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            height: 40,
            marginBottom: 20,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 40,
          marginBottom: 20,
        },
      },
    },
  },
});

function CardsContainer({ isBookmarked, usersMap, userCategories, userCategoriesColorMap }) {
  const [cards, setCards] = useState(null);
  const [total, setTotal] = useState(0);
  const [request, setRequest] = useState(initialRequest);
  const [filterView, setFilterView] = useState(false);
  const [expandableRows, setExpandableRows] = useState({});
  const [previousFilters, setPreviousFilters] = useState(initialRequest);
  const [previousRequest , setPreviousRequest] = useState(null);
  const [bookamrakLoader, setBookamrakLoader] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(defaultPageSize);
  const maxPage = Math.max(Math.ceil(total / rowsPerPage) - 1, 0);
  const currentPage = Math.max(Math.min(request?.pagination?.page || 0, maxPage), 0);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [faviconUrls, setFaviconUrls] = useState({});
  const [expandedFavicons, setExpandedFavicons] = useState({});

  const handleFaviconsExpandCollapse = (uid) => {
    setExpandedFavicons((prev) => ({
      ...prev,
      [uid]: !prev[uid], 
    }));
  };

  useEffect(() => {
    if (cards) {
      cards.forEach((row) => {
        if (row?.favicon) {
          // Construct the API URL
          const apiUrl = `https://cdn.anonimg.cc/${row.favicon}`;
          
          // Perform the API request
          axios({
            method: 'post', // Use 'post' method
            url: apiUrl,
            headers: {
              // Include any headers required by the API
              'Content-Type': 'application/json',
            },
            data: {
              // Include any body data required by the API
              favicon: row.favicon,
            }
          })
          .then((response) => {
            if (response.status === 200) {
              setFaviconUrls(prevState => ({
                ...prevState,
                [row.uid]: apiUrl
              }));
            }
          })
          .catch((error) => {
            console.log(`Error fetching favicon for ${row.favicon}:`, error);
          });
        }
      });
    }
  }, [cards]);

  const handleCategoryExpandCollapse = (uid) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [uid]: !prev[uid],
    }));
  };

  useEffect(() => {
    setExpandableRows({});
    setRequest(initialRequest);
    handleGetCardsCall(initialRequest);
    setPreviousFilters(initialRequest);
  }, [isBookmarked]);

  const handleGetCardsCall = async (finalRequest) => {
    setCards(null);
    setTotal(null);
    let response = await getCards(finalRequest);
    setTotal(response?.data?.total);
    setCards(response?.data?.results?.cards);
  };

  const getCards = async (incomingRequest) => {
    try {
      let finalRequest = _.cloneDeep(incomingRequest);
      if (isBookmarked) {
        finalRequest["bookmarkFilter"] = true;
      } else {
        finalRequest["bookmarkFilter"] = false;
      }
      if (finalRequest?.dateFilter?.startDate) {
        finalRequest.dateFilter.startDate = new Date(
          new Date(finalRequest.dateFilter.startDate).setHours(0, 0, 0, 0)
        ).getTime();
      }
      if (finalRequest?.dateFilter?.endDate) {
        finalRequest.dateFilter.endDate = new Date(
          new Date(finalRequest.dateFilter.endDate).setHours(23, 59, 59, 999)
        ).getTime();
      }
      if (!_.isNil(cards) && cards.length > 0) {
        finalRequest.pagination.lastDocId = cards[cards.length - 1].uid;
        finalRequest.pagination.firstDocId = cards[0].uid;
      }
      setPreviousRequest(finalRequest);
      if (finalRequest.pagination.page === Math.floor(total/finalRequest.pagination.size) && finalRequest.pagination.page !== 0){
        finalRequest.pagination.direction = "end"
      } else if (finalRequest.pagination.page === 0){
        finalRequest.pagination.direction = "start"
      } else if (previousRequest && previousRequest.pagination.page < finalRequest.pagination.page){
        finalRequest.pagination.direction = "increase"
      } else if (previousRequest && previousRequest.pagination.page - 1 === finalRequest.pagination.page){
        finalRequest.pagination.direction = "decrease"
      }

      return await apiHelper.post(path?.getCards, finalRequest);
    } catch (error) {
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleUpdateCardsCall = async (dataToUpdate, uid, index) => {
    if (_.isEmpty(uid)) {
      alert(`Unable to process request because uid is not present.`);
      return null;
    }
    let response;
    try {
      response = await apiHelper.put(path?.updateCard + uid, dataToUpdate);
      // console.log("RESPONSE", JSON.stringify(response.data));
      return response?.data;
    } catch (error) {
      alert("Internal Server Error! Please Try Again");
      return null;
    }
  };

  const handleRequestChange = (name, value) => {
    let tempRequest;
    // console.log("NAME ", name, "VALUE", value);
    switch (name) {
      case "search":
        setRequest({ ...request, search: value });
        break;
      case "startDate":
        setRequest({
          ...request,
          dateFilter: { ...request.dateFilter, startDate: value },
        });
        break;
      case "endDate":
        setRequest({
          ...request,
          dateFilter: { ...request.dateFilter, endDate: value },
        });
        break;
      case "bothDates":
        setRequest({
          ...request,
          dateFilter: { startDate: value, endDate: value },
        });
        break;
      case "srFilter":
        setRequest({ ...request, srFilter: value });
        break;
      case "cardCategoryFilter":
        setRequest({ ...request, cardCategoryFilter: value });
        break;
      case "typeFilter":
        value === "deselect" ? setRequest({ ...request, typeFilter: [] }) : setRequest({ ...request, typeFilter: [value] });
        break;
      case "page":
        tempRequest = _.cloneDeep(request);
        tempRequest.pagination = { ...tempRequest.pagination, page: value };
        // console.log("TEMP REQUEST ", JSON.stringify(tempRequest));
        setPreviousFilters(tempRequest);
        handleGetCardsCall(tempRequest);
        setExpandableRows({});
        setExpandedCategories({});
        setRequest(tempRequest);
        break;
      case "size":
        tempRequest = _.cloneDeep(request);
        tempRequest.pagination = { page: 0, size: value };
        // console.log("TEMP REQUEST ", JSON.stringify(tempRequest));
        setPreviousFilters(tempRequest);
        handleGetCardsCall(tempRequest);
        setExpandableRows({});
        setExpandedCategories({});
        setRequest(tempRequest);
        break;
      case "filterReset":
        setExpandableRows({});
        setExpandedCategories({});
        handleGetCardsCall(initialRequest);
        setRequest(initialRequest);
        setPreviousFilters(initialRequest);
        break;
      case "reset":
        handleGetCardsCall(previousFilters);
        setExpandableRows({});
        setExpandedCategories({});
        setRequest(previousFilters);
        break;
      case "filterCancel":
        tempRequest = _.cloneDeep(previousFilters);
        tempRequest.pagination = request.pagination;
        tempRequest.search = request.search;
        setRequest(tempRequest);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (filterView) setFilterView(false);
    // Changing it to page 0 will automatically call the API
    handleRequestChange("page", 0);
  };

  const handleBookmarkClick = async (e, value, uid, index) => {
    e.preventDefault();
    setBookamrakLoader((prevState) => ({
      ...prevState,
      [index]: true,
    }));
    let updatedValue = true;
    if (value && value === true) {
      updatedValue = false;
    }
    let updatedCard = await handleUpdateCardsCall(
      { isBookmarked: updatedValue },
      uid,
      index
    );
    if (updatedCard) {
      let tempCards = _.cloneDeep(cards);
      tempCards[index] = updateSelectedFields(updatedCard, tempCards[index]);
      setCards(tempCards);
    }
    setBookamrakLoader((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  const handleExportClick = (e) => {
    e.preventDefault();
    const getExportedData = async () => {
      try {
        let finalRequest = { ...request };
        finalRequest.pagination = {
          page: 0,
          size: 100,
        };
        let response = await getCards(finalRequest);
        // console.log("RESPONSE : " + JSON.stringify(response));
        if (response?.data?.results?.cards?.length === 0) {
          alert("No Data to Export");
          return;
        }
        const excelData = response?.data?.results?.cards?.map((card) => {
          return {
            Name: card?.person_name,
            Company: card?.company_name,
            Designation: card?.designation,
            User: usersMap?.[card?.user_id]?.displayName || "",
            Categories: card?.categories?.join(", "),
            Emails: card?.emails?.join(", "),
            ["Mobile Numbers"]: card?.mobile_numbers?.join(", "),
            ["Created Date"]: card?.created_at
              ? new Date(card.created_at).toDateString()
              : null,
          };
        });
        // console.log("EXCEL DATA: " + JSON.stringify(excelData));
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(excelData);
        XLSX.utils.book_append_sheet(
          wb,
          ws,
          `${isBookmarked ? "Bookmarked" : "All"} Cards Data`
        );

        const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });
        const fileName = `${isBookmarked ? "Bookmarked" : "All"
          } Cards Data_${new Date().toISOString()}.xlsx`;
        saveAs(blob, fileName);
      } catch (error) {
        console.error("Error in exporting cards", error);
        alert("Error Ocuured in Exporting Data. Please Try Again later");
      }
    };
    function s2ab(s) {
      const buffer = new ArrayBuffer(s.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buffer;
    }
    getExportedData();
  };

  const handleCopyClick = (e, contentType, contentToCopy) => {
    e.preventDefault();
    try {
      navigator?.clipboard?.writeText(contentToCopy).catch((error) => {
        alert("Failed to copy!");
        console.error("COPY FAILED:", error);
      });
      let type;
      switch (contentType) {
        case "emails":
          type = "Emails";
          break;
        case "mobile_numbers":
          type = "Mobile numbers";
          break;
        case "address":
          type = "Address";
          break;
        default:
          type = "Content";
          break;
      }
      toast.success(`${type} copied to clipboard.`, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      alert("Failed to copy!");
      console.error("COPY FAILED:", error);
    }
  };

  const handleScanExpandCollapse = (e, uid) => {
    e.preventDefault();
    if (_.isEmpty(uid)) {
      alert("UID is not present for the card");
      return;
    }
    setExpandableRows({
      ...expandableRows,
      [uid]: !expandableRows[uid],
    });
  };

  const isAppliedRequestPresent = () => {
    if (
      _.isEmpty(previousFilters?.dateFilter?.startDate) &&
      _.isEmpty(previousFilters?.dateFilter?.endDate) &&
      _.isEmpty(previousFilters?.srFilter) &&
      _.isEmpty(previousFilters?.cardCategoryFilter) &&
      _.isEmpty(previousFilters?.typeFilter)
    ) {
      return false;
    }
    return true;
  };

  const handleRowsPerPageChange = (e) => {
    const newSize = parseInt(e?.target?.value, 10);
    if (newSize) {
      handleRequestChange("size", newSize);
      setRowsPerPage(e?.target?.value);
    }
  };

  return (
    <>
      <div>
        <style>
          {`
            @media (max-width: 1300px) {
              .responsive-table-cell {
                font-size: 12px;
                width: 18%;
              }
            }
            @media (max-width: 1200px) {
              .responsive-table-cell {
                font-size: 13px;
                width: 17%;
              }
            }
            @media (max-width: 1145px) {
              .responsive-table-cell {
                font-size: 12px;
                width: 15%;
              }
            }
            @media (max-width: 1100px) {
              .responsive-table-cell {
                font-size: 10.5px;
                width: 14%;
              }
            }
           @media (min-width:  1000px) and (max-width: 1175px)  {
              .responsive-table-cell {
                font-size: 8px;
                width: 9%;
              }
            }
            @media (max-width: 1000px) {
              .responsive-table-cell {
                font-size: 9px;
                width: 8%;
              }
            }
            .responsive-icon {
              width: 30px;
              height: 30px;
            }
            @media (max-width: 1300px) {
             .responsive-icon {
                width: 24px;
                height: 24px;
              }
            }
            @media (max-width: 1200px) {
             .responsive-icon {
                width: 20px;
                height: 20px;
              }
            }
            @media (min-width:  1000px) and (max-width: 1175px) {
             .responsive-icon {
                width: 17px;
                height: 17px;
              }
            }
          }
          .responsive-chip {
            font-size: 13px;
            padding: 4px 8px;
          }
          @media (max-width: 1300px) {
            .responsive-chip {
              font-size: 11px;
              padding: 3px 6px;
            }
          }
          @media (max-width: 1200px) {
            .responsive-chip {
              font-size: 10px;
              padding: 2px 5px;
            }
          }
          @media (min-width:  1000px) and (max-width: 1175px)  {
            .responsive-chip {
              font-size: 9px;
              padding: 1px 1px;
            }
          }
        }
     `}
     </style>
        <ThemeProvider theme={theme}>
          <form onSubmit={handleSubmit}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search for a name, company name or designation"
                  value={request?.search}
                  onChange={(e) => {
                    e.preventDefault();
                    handleRequestChange("search", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      setFilterView(true);
                    }}
                    startIcon={<FilterListIcon />}
                    sx={{ textTransform: "none", fontSize: "1rem" }}
                  >
                    Filter
                    {isAppliedRequestPresent() && <span style={{ color: '#00ffa9', fontSize: '1.5rem', marginLeft: '5px' }}>*</span>}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={handleExportClick}
                    sx={{ textTransform: "none", fontSize: "1rem" }}
                    startIcon={<FileDownloadIcon />}
                  >
                    Export
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRequestChange("reset");
                    }}
                    sx={{ textTransform: "none", fontSize: "1rem" }}
                    startIcon={<RefreshIcon />}
                  >
                    Refresh
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <FilterPopup
              filterView={filterView}
              setFilterView={setFilterView}
              request={request}
              handleRequestChange={handleRequestChange}
              userCategories={userCategories}
              usersMap={usersMap}
              handleSubmit={handleSubmit}
              initialRequest={initialRequest}
              previousFilters={previousFilters}
              setRequest={setRequest}
            />
          </form>
        </ThemeProvider>
        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">
                    Company Name
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">Designation</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">
                    Sales Representative
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">
                    Category
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">Scan type</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }} className="responsive-table-cell">
                    Created Date
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {cards ? (
                  cards.map((row, index) => (
                    <React.Fragment key={row?.uid}>
                      <TableRow
                        key={row?.uid}
                        style={{
                          backgroundColor:
                            index % 2 === 1 ? "white" : "#ECECEC",
                        }}
                      >
                        <TableCell className="responsive-table-cell">
                          {bookamrakLoader[index] ? (
                            <CircularProgress size={20} thickness={7} />
                          ) : (
                            <IconButton
                              onClick={(e) =>
                                handleBookmarkClick(
                                  e,
                                  row?.isBookmarked,
                                  row?.uid,
                                  index
                                )
                              }
                              color="primary"
                            >
                              {row?.isBookmarked ? (
                                <BookmarkIcon />
                              ) : (
                                <BookmarkBorderIcon />
                              )}
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell className="responsive-table-cell">
                          {Array.isArray(row?.favicons) && row?.favicons.length > 1 ? (
                            <>
                              {!expandedFavicons[row?.uid] ? (
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                  <img
                                    src={row.favicons[0]}
                                    alt="favicon-0"
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                      borderRadius: '50%',
                                      objectFit: 'cover',
                                    }}
                                  />
                                  <div
                                    onClick={() => handleFaviconsExpandCollapse(row?.uid)}
                                    style={{
                                      position: 'absolute',
                                      bottom: '-5px',
                                      right: '-10px',
                                      backgroundColor: '#4285F4',
                                      color: 'white',
                                      borderRadius: '50%',
                                      width: '24px',
                                      height: '24px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    +{row?.favicons.length - 1}
                                  </div>
                                </div>
                              ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                  {row.favicons.map((favicon, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginRight: '10px',
                                      }}
                                    >
                                      <img
                                        src={favicon}
                                        alt={`favicon-${index}`}
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                          borderRadius: '50%',
                                          objectFit: 'cover',
                                        }}
                                      />
                                    </div>
                                  ))}
                                  <div
                                    onClick={() => handleFaviconsExpandCollapse(row.uid)}
                                    style={{
                                      backgroundColor: '#4285F4',
                                      color: 'white',
                                      borderRadius: '50%',
                                      width: '24px',
                                      height: '24px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    -
                                  </div>
                                </div>
                              )}
                            </>
                          ) : row?.favicons ? (
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                              <img
                                src={row?.favicons}
                                alt="favicon"
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '50%',
                                  objectFit: 'cover',
                                }}
                              />
                            </div>
                          ) : null}
                        </TableCell>
                        <TableCell onClick={(e) => handleScanExpandCollapse(e, row?.uid)} className="responsive-table-cell">
                          {row?.person_name}
                        </TableCell>
                        <TableCell
                          onClick={(e) => handleScanExpandCollapse(e, row?.uid)}
                          className="responsive-table-cell"
                        >
                          {row?.company_name}
                        </TableCell>
                        <TableCell
                          onClick={(e) => handleScanExpandCollapse(e, row?.uid)}
                          style={{ marginRight: "0px" }}
                          className="responsive-table-cell"
                        >
                          {row?.designation}
                        </TableCell>
                        <TableCell
                          onClick={(e) => handleScanExpandCollapse(e, row?.uid)}
                          className="responsive-table-cell"
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {usersMap?.[row?.user_id]?.gender === "male" ? (
                              <Tooltip>
                                <img
                                  src={maleIcon}
                                  alt="Male Icon"
                                  className="responsive-icon"
                                  style={{ paddingRight: "4px" }}
                                />
                              </Tooltip>
                            ) : usersMap?.[row?.user_id]?.gender === "female" ? (
                              <Tooltip>
                                <img
                                  src={femaleIcon}
                                  alt="Female Icon"
                                  className="responsive-icon"
                                  style={{ paddingRight: "4px" }}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip>
                                <img
                                  src={profileIcon}
                                  alt="Profile Icon"
                                  className="responsive-icon"
                                  style={{ paddingRight: "4px" }}
                                />
                              </Tooltip>
                            )}
                            {usersMap?.[row?.user_id]?.displayName || ""}
                          </div>
                        </TableCell>
                        <TableCell className="responsive-table-cell">
                          {row?.categories?.length > 1 ? (
                            <>
                              <Chip
                                key={row?.categories[0]}
                                label={row?.categories[0]}
                                className="responsive-chip"
                                sx={{ color: "white", backgroundColor: userCategoriesColorMap?.[row?.categories[0]] || "#875053" }}
                              />
                              {!expandedCategories[row?.uid] ? (
                                <div style={{ display: "block" }}>
                                  <Chip
                                    label={`+${row?.categories?.length - 1}`}
                                    className="responsive-chip"
                                    sx={{
                                      marginLeft: "4px",
                                      backgroundColor: "#49C1C9",
                                      color: "white",
                                      marginTop: "4px"
                                    }}
                                    onClick={() => handleCategoryExpandCollapse(row?.uid)}
                                  />
                                </div>
                              ) : (
                                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "5px" }}>
                                  {row?.categories?.slice(1).map((category) => (
                                    <Chip
                                      key={category}
                                      label={category}
                                      className="responsive-chip"
                                      sx={{
                                        color: "white",
                                        backgroundColor: userCategoriesColorMap?.[category] || "#875053",
                                        marginBottom: "4px",
                                      }}
                                    />
                                  ))}
                                  <Chip
                                    label="-"
                                    className="responsive-chip"
                                    sx={{
                                      marginLeft: "5px",
                                      backgroundColor: "#49C1C9",
                                      color: "white",
                                    }}
                                    onClick={() => handleCategoryExpandCollapse(row?.uid)}
                                  />
                                </div>
                              )}
                            </>
                          ) : (
                            row?.categories?.map((category) => (
                              <Chip
                                key={category}
                                label={category}
                                className="responsive-chip"
                                sx={{ color: "white", backgroundColor: userCategoriesColorMap?.[category] || "black" }}
                              />
                            ))
                          )}
                        </TableCell>
                        <TableCell onClick={(e) => handleScanExpandCollapse(e, row?.uid)} className="responsive-table-cell">
                          <Chip
                            label={(row?.type && cardTypeMap?.[row.type]?.label) || row?.type}
                            className="responsive-chip"
                            sx={{
                              color: "white",
                              backgroundColor:
                                (row?.type && cardTypeMap?.[row.type]?.color) || "",
                            }}
                          />
                        </TableCell>
                        <TableCell
                          onClick={(e) => handleScanExpandCollapse(e, row?.uid)}
                          className="responsive-table-cell"
                        >
                          {row?.created_at
                            ? new Date(row?.created_at).toLocaleDateString(undefined, {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })
                            : null}
                        </TableCell>
                        <TableCell colSpan={3} style={{ textAlign: "left" }} className="responsive-table-cell">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {!_.isEmpty(row?.mobile_numbers) && (
                                <Button
                                  variant="text"
                                  onClick={(e) => handleCopyClick(e, "mobile_numbers", row?.mobile_numbers)}
                                  style={{ padding: 0, minWidth: 'auto', margin: '0 2px' }}
                                >
                                  <img
                                    src={callIcon}
                                    alt="Call Icon"
                                    className="responsive-icon"
                                    style={{ cursor: "pointer", margin: '0 2px' }}
                                  />
                                </Button>
                              )}
                              {!_.isEmpty(row?.emails) && (
                                <Button
                                  variant="text"
                                  onClick={(e) => handleCopyClick(e, "emails", row?.emails)}
                                  style={{ padding: 0, minWidth: 'auto', margin: '0 2px' }}
                                >
                                  <img
                                    src={mailIcon}
                                    alt="Mail Icon"
                                    className="responsive-icon"
                                    style={{ cursor: "pointer", margin: '0 2px' }}
                                  />
                                </Button>
                              )}
                              {!_.isEmpty(row?.address) && (
                                <Button
                                  variant="text"
                                  onClick={(e) => handleCopyClick(e, "address", row?.address)}
                                  style={{ padding: 0, minWidth: 'auto', margin: '0 2px' }}
                                >
                                  <img
                                    src={locationIcon}
                                    alt="Location Icon"
                                    className="responsive-icon"
                                    style={{ cursor: "pointer", margin: '0 2px' }}
                                  />
                                </Button>
                              )}
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow key={`${row?.uid}-details`}>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            backgroundColor: "#f4f4f4",
                            width: "100%",
                          }}
                          colSpan={11}
                          className="responsive-table-cell"
                        >
                          <Collapse
                            in={expandableRows[row.uid]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={2}>
                              <CardDetails
                                cards={cards}
                                setCards={setCards}
                                index={index}
                                initialCardData={row}
                                usersMap={usersMap}
                                userCategories={userCategories}
                                handleUpdateCardsCall={handleUpdateCardsCall}
                                handleCopyClick={handleCopyClick}
                              />
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} style={{ borderBottom: "none" }} className="responsive-table-cell">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 436,
                          width: "100%",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div
            style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <TablePagination
              component="div"
              count={total || 0}
              rowsPerPageOptions={Array.from({ length: 50 }, (_, index) => index + 1)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={currentPage}
              onPageChange={(e, newPage) => {
                e.preventDefault();
                handleRequestChange("page", newPage);
              }}
              ActionsComponent={TablePaginationActions}
              slotProps={{
                select: {
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                      },
                      component: styled('div')(({ theme }) => ({
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        scrollbarWidth: 'none'
                      }))
                    },
                  },
                },
              }}
            />
          </div>
        </Paper>
      </div>
      <ToastContainer />
    </>
  );
}

export default CardsContainer;
