import React, { useEffect, useState } from "react";
import { Card, CardContent, CircularProgress } from "@mui/material";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarController,
    BarElement,
    Title,
    Legend,
    Tooltip
} from 'chart.js';
const _ = require("lodash");

ChartJS.register(
    Title,
    Legend,
    Tooltip,
    CategoryScale,
    LinearScale,
    BarElement,
    BarController
);

const options = {
    plugins: {
        legend: {
            display: false,
        },
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                autoSkip: false, 
            }
        },
        y: {
            grid: {
                display: false,
            },
        },
    },
    layout: {
        padding: {
            top: 50, 
        },
    },
    maintainAspectRatio: false,
    responsive: true,
};

const MultiBarChart = ({ data }) => {
    const [finalGraphData, setFinalGraphData] = useState(null);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            const labels = data?.labels;
            const scanData = data?.data?.scan;
            const contactData = data?.data?.contact;
            const graphData = {
                labels: labels,
                datasets: [{
                    label: "Scanned",
                    data: scanData,
                    fill: false,
                    backgroundColor: '#009688',
                    tension: 0.1,
                    barThickness: 30,
                }, {
                    label: "Contact",
                    data: contactData,
                    fill: false,
                    backgroundColor: '#F39523',
                    tension: 0.1,
                    barThickness: 30,
                }],
            };
            setFinalGraphData(graphData);
        } else {
            setFinalGraphData(null);
        }
    }, [data]);

    return data && finalGraphData ? (
        <Card style={{ height: "46.5vh", width: "100%" }}> 
            <CardContent style={{ height: "100%", position: "relative" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    <Bar data={finalGraphData} options={options} style={{ height: "100%", width: "100%" }} /> {/* Ensure Bar chart takes full height */}
                </div>
                <div style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding: "5px",
                    borderRadius: "5px",
                    backgroundColor: "rgba(255, 255, 255, 0.8)", 
                }}>
                    <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
                        <div style={{
                            width: 10,
                            height: 10,
                            backgroundColor: '#009688',
                            borderRadius: '50%',
                            marginRight: 5
                        }}></div>
                        <span style={{ fontSize: "12px" }}>Scanned</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{
                            width: 10,
                            height: 10,
                            backgroundColor: '#F39523',
                            borderRadius: '50%',
                            marginRight: 5
                        }}></div>
                        <span style={{ fontSize: "12px" }}>Contact</span>
                    </div>
                </div>
            </CardContent>
        </Card>
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
            }}
        >
            <CircularProgress />
        </div>
    );
};

export default MultiBarChart;

