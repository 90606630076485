import React, { useEffect, useState } from "react";
import {
  TextField,
  Grid,
  Typography,
  CardMedia,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Button
} from "@mui/material";
import { Card, CardContent } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { createdataToUpdateObject, updateSelectedFields } from "../../utils";
import MarkForImprovementPopup from "./popups/MarkForImprovementPopup";
import LaunchIcon from '@mui/icons-material/Launch';
import WebsitePopup from "./popups/WebsitePopup";
const _ = require("lodash");

const CardDetails = ({
  cards,
  setCards,
  index,
  initialCardData,
  usersMap,
  userCategories,
  handleUpdateCardsCall,
  handleCopyClick,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [tempData, setTempData] = useState(initialCardData);
  const [markForImprovementDialogOpen, setMarkForImprovementDialogOpen] = useState(false);
  const [websitePopupOpen, setWebsitePopupOpen] = useState(false);
  const [urlToRedirect, setUrlToRedirect] = useState('');
  const [isMarkedForImprovement, setIsMarkedForImprovement] = useState(initialCardData?.isMarkedForImprovement)

  const settingTempData = () => {
    // Handling Comma Seperated Fields
    let tempRow = _.cloneDeep(initialCardData);
    if (!_.isEmpty(tempRow?.emails)) {
      tempRow.emails = tempRow.emails.join(", ");
    }
    if (!_.isEmpty(tempRow?.mobile_numbers)) {
      tempRow.mobile_numbers = tempRow.mobile_numbers.join(", ");
    }
    setTempData(tempRow);
  }

  useEffect(() => {
    settingTempData();
  }, [initialCardData]);


  const handleUpdate = async () => {
    let dataToUpdate = _.cloneDeep(tempData);
    if (!_.isEmpty(dataToUpdate?.emails)) {
      dataToUpdate.emails = dataToUpdate.emails
        .split(",")
        .map((email) => email.trim());
    } else {
      dataToUpdate.emails = []
    }
    if (!_.isEmpty(dataToUpdate?.mobile_numbers)) {
      dataToUpdate.mobile_numbers = dataToUpdate.mobile_numbers
        .split(",")
        .map((mobile_numbers) => mobile_numbers.trim());
    } else {
      dataToUpdate.mobile_numbers = []
    }
    dataToUpdate = createdataToUpdateObject(dataToUpdate, initialCardData);
    if (Object.keys(dataToUpdate).length === 0) {
      alert("No data to update");
      setEditMode(false);
      return;
    }
    setTempData(null);
    // console.log("DATA TO UPDATE : " + JSON.stringify(dataToUpdate));
    let updatedCard = await handleUpdateCardsCall(dataToUpdate, initialCardData?.uid, index);
    if (updatedCard) {
      let tempCards = _.cloneDeep(cards);
      tempCards[index] = updateSelectedFields(updatedCard, tempCards[index]);
      setCards(tempCards);
    } else {
      settingTempData();
    }
    setEditMode(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "categories") {
      setTempData({
        ...tempData,
        [name]: typeof value === "string" ? value.split(",") : value,
      });
    } else {
      setTempData({ ...tempData, [name]: value });
    }
  };

  const handleMarkForImprovementDialogOpen = (e) => {
    e.preventDefault();
    setMarkForImprovementDialogOpen(true);
  };

  const handleMarkForImprovementDialogClose = () => {
    setMarkForImprovementDialogOpen(false);
  };

  const formatURL = (url) => {
    if (!url) return '';
    return url?.startsWith('https://') ? url : `https://${url}`;
  };

  const handleWebsitePopupOpen = (url) => {
    setUrlToRedirect(url);
    setWebsitePopupOpen(true);
  };

  const handleWebsitePopupClose = () => {
    setWebsitePopupOpen(false);
    setUrlToRedirect('');
  };

  const handleWebsiteConfirm = () => {
    if (!_.isEmpty(urlToRedirect)) {
      window.open(urlToRedirect);
    }
    handleWebsitePopupClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            marginRight: "25px",
            backgroundColor: isMarkedForImprovement ? "#808080" : "primary.main"
          }}
          onClick={handleMarkForImprovementDialogOpen}
        >
          Mark for improvement
        </Button>
        <MarkForImprovementPopup
          isPopupOpen={markForImprovementDialogOpen}
          handlePopupClose={handleMarkForImprovementDialogClose}
          cardData={initialCardData}
          setIsMarkedForImprovement={setIsMarkedForImprovement}
        />
        {editMode ? (
          <div>
            <IconButton onClick={handleUpdate} style={{ color: "black" }}>
              <CheckIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setTempData(initialCardData);
                setEditMode(false);
              }}
              style={{ color: "black" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <IconButton
            onClick={() => {
              setEditMode(true);
            }}
            style={{ color: "black" }}
          >
            <EditIcon />
          </IconButton>
        )}
      </div>
      <Grid container spacing={2} marginBottom={5} marginLeft={1}>
        {tempData ? (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                name={"person_name"}
                onChange={handleChange}
                value={tempData?.person_name || ""}
                disabled={!editMode}
                style={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Company Name"
                variant="outlined"
                fullWidth
                name={"company_name"}
                onChange={handleChange}
                value={tempData?.company_name || ""}
                disabled={!editMode}
                style={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Designation"
                variant="outlined"
                fullWidth
                name={"designation"}
                onChange={handleChange}
                value={tempData?.designation || ""}
                disabled={!editMode}
                style={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Emails"
                variant="outlined"
                fullWidth
                name={"emails"}
                onChange={handleChange}
                value={tempData?.emails || ""}
                disabled={!editMode}
                style={{ backgroundColor: "white" }}
                InputProps={{
                  endAdornment: !editMode && (
                    <InputAdornment position="end">
                      <Tooltip title="Copy">
                        <IconButton
                          onClick={(e) => handleCopyClick(e, "emails", tempData?.emails)}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Mobile Numbers"
                variant="outlined"
                fullWidth
                name={"mobile_numbers"}
                onChange={handleChange}
                value={tempData?.mobile_numbers || ""}
                disabled={!editMode}
                style={{ backgroundColor: "white" }}
                InputProps={{
                  endAdornment: !editMode && (
                    <InputAdornment position="end">
                      <Tooltip title="Copy">
                        <IconButton
                          onClick={(e) => handleCopyClick(e, "mobile_numbers", tempData?.mobile_numbers)}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Website/url"
                variant="outlined"
                fullWidth
                name="website_url"
                onChange={handleChange}
                value={tempData?.website || ''}
                disabled={!editMode}
                style={{ backgroundColor: 'white' }}
                InputProps={{
                  endAdornment: !editMode && tempData?.website && (
                    <InputAdornment position="end">
                      <Tooltip title="Visit Website">
                        <IconButton onClick={() => handleWebsitePopupOpen(formatURL(tempData?.website))}>
                          <LaunchIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <WebsitePopup
              isPopupOpen={websitePopupOpen}
              handlePopupClose={handleWebsitePopupClose}
              handleConfirm={handleWebsiteConfirm}
            />
            <Grid item xs={12} md={6}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                name={"address"}
                onChange={handleChange}
                value={tempData?.address || ""}
                disabled={!editMode}
                style={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="category-select-label">
                  User Category
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-multiple-chip"
                  multiple
                  name="categories"
                  value={tempData.categories || []}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="User Category"
                    />
                  }
                  renderValue={(selected) => (
                    <div
                      style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </div>
                  )}
                  disabled={!editMode}
                  style={{ backgroundColor: "white" }}
                >
                  {userCategories.map((category, index) => (
                    <MenuItem key={index} value={category?.userCategory}>
                      {category?.userCategory}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Notes"
                variant="outlined"
                fullWidth
                name={"note"}
                onChange={handleChange}
                value={tempData?.note || ""}
                style={{ backgroundColor: "white" }}
                disabled={!editMode}
              />
            </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Sales Representative"
                  variant="outlined"
                  fullWidth
                  value={usersMap?.[initialCardData?.user_id]?.displayName || ""}
                  style={{ backgroundColor: "white" }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Type"
                  variant="outlined"
                  fullWidth
                  value={initialCardData?.type || ""}
                  style={{ backgroundColor: "white" }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Created Date"
                  type="date"
                  variant="outlined"
                  fullWidth
                  value={
                    tempData?.created_at
                      ? new Date(tempData?.created_at)
                        .toISOString()
                        .split("T")[0]
                      : ""
                  }
                  style={{ backgroundColor: "white" }}
                  disabled
                />
              </Grid>

            {initialCardData?.front_img_bucket && (
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Front Image
                    </Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    alt="Front Image"
                    style={{
                      maxHeight: "250px",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    image={initialCardData.front_img_bucket}
                    title="Front Image"
                  />
                </Card>
              </Grid>
            )}
            {initialCardData?.back_img_bucket && (
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Back Image
                    </Typography>
                  </CardContent>
                  <CardMedia
                    component="img"
                    alt="Back Image"
                    style={{
                      maxHeight: "250px",
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    image={initialCardData.back_img_bucket}
                    title="Back Image"
                  />
                </Card>
              </Grid>
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 436,
              width: "100%",
            }}
          >
            <CircularProgress />
          </div>
        )}
      </Grid>
    </>
  );
};

export default CardDetails;
