import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Button,
  Box,
  IconButton,
  Tooltip
} from "@mui/material";
import { Switch } from "@mui/material";
import AddSalesRepresentativePopup from "./Popups/AddSalesRepresentativePopup";
import LockResetIcon from "@mui/icons-material/LockReset";
import ForgotPasswordPopup from "./Popups/ForgotPasswordPopup";
import RevokePopup from "./Popups/RevokePopup";
import UserCategoriesContainer from "./UserCategoriesContainer";
import AdminPasswordChangeContainer from "./AdminPasswordChangeContainer";
import maleIcon from "../../assets/icons/male_icon.svg";
import femaleIcon from "../../assets/icons/female_icon.svg";
import profileIcon from "../../assets/icons/profile_icon.svg";
const _ = require("lodash");

function SettingsContainer({
  users,
  setUsers,
  handleGetUserCall,
  handleGetUserCategoryCall,
  userCategories,
  userCategoriesColorMap,
  setUserCategories,
}) {
  const [isSalesRepPopupOpen, setIsSalesRepPopupOpen] = useState(false);
  const [isForgotPasswordPopupOpen, setIsForgotPasswordPopupOpen] = useState(false);
  const [resetAgentPasswordUser, setResetAgentPasswordUser] = useState(null);
  const [isRevokePopupOpen, setIsRevokePopupOpen] = useState(false)
  const [revokeData, setRevokeData] = useState(null);

  const handleSalesRepPopupOpen = (e) => {
    e.preventDefault();
    setIsSalesRepPopupOpen(true);
  };

  const handleForgotPasswordPopupOpen = (e, row) => {
    e.preventDefault();
    setIsForgotPasswordPopupOpen(true);
    setResetAgentPasswordUser(row);
  };

  const handleRevokePopupOpen = (e, agentUid, isActive) => {
    e.preventDefault();
    setIsRevokePopupOpen(true);
    setRevokeData({ agentUid, isActive });
  };

  const handleSalesRepPopupClose = () => {
    setIsSalesRepPopupOpen(false);
  };

  const handleForgotPasswordPopupClose = (e) => {
    setIsForgotPasswordPopupOpen(false);
  };

  const handleRevokePopupClose = () => {
    setIsRevokePopupOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h3 style={{ margin: 0 }}>Sales Representatives</h3>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSalesRepPopupOpen}
          sx={{
            textTransform: "none",
            fontSize: "1rem",
            padding: "12px",
            lineHeight: 1.2,
          }}
        >
          Add Sales Representative
        </Button>
      </Box>
      {isSalesRepPopupOpen && (
        <AddSalesRepresentativePopup
          isPopupOpen={isSalesRepPopupOpen}
          handlePopupClose={handleSalesRepPopupClose}
          users={users}
          setUsers={setUsers}
          handleGetUserCall={handleGetUserCall}
        />
      )}
      {isForgotPasswordPopupOpen && (
        <ForgotPasswordPopup
          isPopupOpen={isForgotPasswordPopupOpen}
          handlePopupClose={handleForgotPasswordPopupClose}
          user={resetAgentPasswordUser}
        />
      )}
      {isRevokePopupOpen && (<>
        <RevokePopup
          revokeData={revokeData}
          handleGetUserCall={handleGetUserCall}
          setUsers={setUsers}
          handleRevokePopupClose={handleRevokePopupClose}
          isRevokePopupOpen={isRevokePopupOpen}
        />
      </>
      )}
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          border: "1px solid #d0d0d0",
        }}
      >
        <TableContainer sx={{ maxHeight: 420 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    width: "15%",
                    textAlign: "center"
                  }}
                >
                  Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "15%", textAlign: "center" }}>
                  <Box>Email</Box>
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "15%", textAlign: "center" }}>
                  Created Date
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "15%", textAlign: "center" }}>
                  Last Scan Date
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "15%", textAlign: "center" }}>
                  Cards Scanned
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "15%", textAlign: "center" }}>
                  Reset Password
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", width: "10%", textAlign: "center" }}>
                  Active
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users ? (
                users.map((row, index) => (
                  <TableRow key={row?.uid}>
                    <TableCell style={{ paddingLeft: "40px", paddingRight: "0px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {row?.gender === "male" ? (
                          <Tooltip>
                            <img
                              src={maleIcon}
                              alt="Male Icon"
                              style={{ paddingRight: "4px" }}
                            />
                          </Tooltip>
                        ) : row?.gender === "female" ? (
                          <Tooltip>
                            <img
                              src={femaleIcon}
                              alt="Female Icon"
                              style={{ paddingRight: "4px" }}
                            />
                          </Tooltip>
                        ) : <Tooltip>
                          <img
                            src={profileIcon}
                            alt="Profile Icon"
                            style={{ paddingRight: "4px" }}
                          />
                        </Tooltip>}
                        {row?.display_name}
                      </div>
                    </TableCell>
                    <TableCell
                      style={{ paddingLeft: "40px", alignItems: "center" }}
                    >
                      {row?.email}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {row?.created_time?._seconds
                        ? new Date(
                          row.created_time._seconds * 1000
                        ).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                        : null}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {row?.last_scan_date
                        ? new Date(
                          row?.last_scan_date?._seconds * 1000
                        ).toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })
                        : null}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {row?.total_scans_count || 0}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Tooltip title="Reset Password">
                        <IconButton onClick={(e) => handleForgotPasswordPopupOpen(e, row)}>
                          <LockResetIcon
                            sx={{ color: "#007BFF" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Switch
                        checked={row?.is_active === true}
                        onChange={(e) =>
                          handleRevokePopupOpen(e, row?.uid, row?.is_active)
                        }
                        sx={{
                          "& .MuiSwitch-thumb": {
                            color: row?.is_active ? "#28A745" : "#FF0000",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.5)",
                          },
                          "& .MuiSwitch-track": {
                            backgroundColor: row?.is_active
                              ? "#28A745"
                              : "#FF0000",
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.5)",
                          },
                          "& .Mui-checked + .MuiSwitch-track": {
                            backgroundColor: "#28A745",
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} style={{ borderBottom: "none" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 200,
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "47.5%" }}>
          <AdminPasswordChangeContainer />
        </div>
        <div style={{ width: "47.5%" }}>
          <UserCategoriesContainer
            userCategories={userCategories}
            userCategoriesColorMap={userCategoriesColorMap}
            setUserCategories={setUserCategories}
            handleGetUserCategoryCall={handleGetUserCategoryCall}
          />
        </div>
      </div>
    </>
  );
}

export default SettingsContainer;
